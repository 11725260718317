import * as React from "react";

import CustomTable from "components/elements/CustomTableDivStyled";
import { BrmMainTable } from "brm/tables/BrmTables";
import { LoadingSpinner as Loading, ErrorBanner } from "components/elements";

import { TYPE, COMMON } from "constants/brm";
import { selectedInstanceState } from "atoms/atoms-content";
import { useRecoilState } from "recoil";
import GenericModal from "components/modals/GenericModal";
import { ThreatApi } from "features/brm";

import {
  createColumnName,
  // createColumnActions,
  createColumnMappedNoEdit,
} from "brm/tables/services/column/columnFactory";

export const OffensiveTechniquesTable = ({ setSelectedRows }: any) => {
  const [selectedInstance, setSelectedInstance] = useRecoilState(selectedInstanceState);
  const [alertOpen, setAlertOpen] = React.useState(false);
  const [editMode] = React.useState(false);
  const updatedName = React.useRef("");

  const { data, error } = ThreatApi.useOffensiveTechniques({ catalog: COMMON.defaultUuid });
  // const { mutate: setThreatCatalogName } = ThreatApi.useSetThreatCatalogName({
  //   options: {
  //     onSettled: () => {
  //       setSelectedInstance({
  //         id: selectedInstance.id,
  //         name: updatedName.current,
  //         type: selectedInstance.type,
  //       });
  //       updatedName.current = "";
  //     },
  //   },
  // });

  // const createButton = React.useCallback(() => {
  //   return COMMON.na;
  // }, []);

  const columns = React.useMemo(
    () => [
      createColumnName(selectedInstance.id, setSelectedInstance, editMode, updatedName, "offensiveTechnique"),
      createColumnMappedNoEdit("descriptionThreat"),
      // createColumnMappedNoEdit("catalog"),
      // createColumnMappedNoEdit("tactic"),
      // createColumnMappedNoEdit("sourceId"),
      // createColumnMappedNoEdit("sourceUrl"),
      createColumnMappedNoEdit("detection"),
      // createColumnMappedNoEdit("isSubtechnique"),
      createColumnMappedNoEdit("parentName"),
      // createColumnMappedNoEdit("parentTechnique"),
      // createColumnMappedNoEdit("dataSource"),
      // createColumnMappedNoEdit("targetTechnique"),
      // createColumnMappedNoEdit("impactType"),
      // createColumnActions(createButton, { disableFilters: true }),
    ],
    [selectedInstance.id, setSelectedInstance, editMode]
  );

  const hideCheckBoxCondition = React.useCallback(() => {
    // if (selfData) {
    //   // hide if we are the builtin catalog && we aren't in administration org...
    //   return row.original.organization.id === COMMON.defaultUuid && selfData.organization !== COMMON.defaultUuid;
    // }
    return true;
  }, []);

  if (error) {
    return <ErrorBanner msg="Error while loading offensive techniques" />;
  }

  if (data) {
    return (
      <>
        {alertOpen && (
          <GenericModal
            elementId="OffensiveTechniquesTable_alertModal"
            modalIsOpen={alertOpen}
            setModalIsOpen={setAlertOpen}
            headerText="Message"
            bodyText="Please select a Offensive Tactic."
          />
        )}

        <CustomTable>
          <BrmMainTable
            data={data}
            columns={columns}
            setSelectedRows={setSelectedRows}
            customProps={{ id: "OffensiveTechniquesTable_table" }}
            elementName={TYPE.offensiveTactic}
            hideCheckBoxCondition={hideCheckBoxCondition}
          />
        </CustomTable>
      </>
    );
  }

  return <Loading />;
};
