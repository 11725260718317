/** ****************************************
 * FYI THERE ARE TWO ALLOCATED_CONTROL_TABLES components and add forms with the same name... the other is in the features/mitigation...
 * why you ask? I have no idea and no time to find out.
 */

import * as React from "react";
import * as Recoil from "recoil";
import { variantIdState } from "atoms/atoms-component";
import { BrmDetailTable } from "brm/tables/BrmTables";
import { DetailTableType, SystemApi, currentProjectState } from "features/brm";
import { ExportButton } from "features/brm/components/elements/ExportButton";
import { GenericAddButton } from "features/brm/components/elements/GenericAddButton";
import { ExportTableModal, useExportTable } from "features/exporter";
import { createColumnMappedNoEdit, createColumnNameDetails } from "brm/tables/services/column/columnFactory";
import { queryClient } from "libs/react-query";
import { ErrorBanner, LoadingSpinner as Loading, CenterText } from "components/elements";
import { useModal } from "hooks";
import { useRoles } from "features/brm/hooks/useRoles";
import { isSubmissionPendingState } from "atoms/atoms-importer";
import { isImportingStatus } from "utils/filter-utils";

// Styles
import * as S from "brm/styles/details-table.styles";
// Api
import { RoutePath } from "routes/route-paths";
import { AddAllocatedDigitalArtifactsDialog } from "features/mitre-d3fend/components/dialogs/AddAllocatedDigitalArtifactDialog";

interface IMitreD3fendDetailsTableProps {
  elementNameInUrl: string;
  selectedElement: any;
  tableTitle: string;
  setIsControlCatalogEditingEnabled?: (input: boolean) => void | undefined;
}

export const MitreDefendDetailsTable = ({
  elementNameInUrl,
  selectedElement,
  tableTitle,
  setIsControlCatalogEditingEnabled,
}: IMitreD3fendDetailsTableProps) => {
  const variantId = Recoil.useRecoilValue(variantIdState);
  const currentProject = Recoil.useRecoilValue(currentProjectState);
  const isSubmissionPending = Recoil.useRecoilValue(isSubmissionPendingState);

  const [, setSelectedAllocatedControl] = React.useState([]);
  const sctmId = React.useRef(
    elementNameInUrl === DetailTableType.VARIANTS.key && selectedElement.sctm?.id
      ? selectedElement.sctm?.id
      : selectedElement.id
  );

  const { isThreatAnalyst } = useRoles();
  const { disableExport, setExportTableData, handleTableExport } = useExportTable();
  const { isVisible, toggle } = useModal();

  const isImporting = currentProject ? isImportingStatus(currentProject) : true;
  const isImportingAndSubmissionDone = !(isImporting || isSubmissionPending);
  const { isVisible: isAddVisible, toggle: toggleAdd } = useModal();

  const columns = React.useMemo(
    () =>
      isThreatAnalyst
        ? [
            createColumnNameDetails(RoutePath.AllocatedControl.replace(":id", "")),
            createColumnMappedNoEdit("attackercat"),
          ]
        : [
            createColumnMappedNoEdit("name"),
            createColumnMappedNoEdit("descriptionThreat"),
            createColumnMappedNoEdit("detection"),
            createColumnMappedNoEdit("isSubtechnique"),
          ],
    [isThreatAnalyst]
  );

  React.useEffect(() => {
    if (elementNameInUrl === DetailTableType.SCTM.key) {
      sctmId.current = selectedElement.id;
    } else if (elementNameInUrl === DetailTableType.VARIANTS.key && selectedElement.sctm?.id) {
      sctmId.current = selectedElement.sctm?.id;
    }
  }, [elementNameInUrl, selectedElement]);

  const {
    data: acData,
    isError: isGetAcDataError,
    // error: AllocatedControlsDataError,
  } = SystemApi.useElementMitreD3fend({
    elementId: selectedElement.id,
    elementName: elementNameInUrl,
    projectId: currentProject?.id,
    sctmId: sctmId.current,
    variantId,
    options: {
      enabled: !!selectedElement.id && !!sctmId.current && !!variantId && isImportingAndSubmissionDone,
      onSuccess: (data) => {
        if (setIsControlCatalogEditingEnabled) {
          setIsControlCatalogEditingEnabled(!(Array.isArray(data) && data.length));
        }
      },
    },
  });

  React.useEffect(() => {
    queryClient.invalidateQueries(["getElementAllocatedControls", selectedElement, elementNameInUrl, variantId]);
  }, [elementNameInUrl, selectedElement, variantId]);

  if (isThreatAnalyst) {
    return <CenterText msg="Mitre D3FEND is not applicable" />;
  }

  if (isGetAcDataError) {
    return <ErrorBanner msg="Error while attempting to load Mitre D3FEND data" />;
  }

  if (!isImportingAndSubmissionDone) {
    return <Loading />;
  }

  if (acData) {
    return (
      <S.DetailsContainer id="MitreD3fendTable_detailsPanel">
        <ExportTableModal onTableExport={handleTableExport} isVisible={isVisible} toggle={toggle} />
        <AddAllocatedDigitalArtifactsDialog isVisible={isAddVisible} toggle={toggleAdd} />
        <S.ActionContainer>
          <S.DetailsTableContainer>
            <BrmDetailTable
              data={acData}
              columns={columns}
              setSelectedElement={setSelectedAllocatedControl}
              customProps={{ id: "MitreD3fendTable_detailsTable" }}
              showRowSelect={false}
              elementName={elementNameInUrl}
              tableTitle={tableTitle}
              setExportTableData={setExportTableData}
            />
          </S.DetailsTableContainer>
          <S.DetailButtonTopCorner>
            <GenericAddButton onClick={() => toggleAdd()} tooltip="Add Allocated Digital Artifact" />
            <ExportButton onClick={() => toggle()} disableExport={disableExport} />
          </S.DetailButtonTopCorner>
        </S.ActionContainer>
      </S.DetailsContainer>
    );
  }

  return <Loading />;
};
