import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./defensive-techniques-query-key-factory";

export const getDefensiveTechniques = ({ catalog }: { catalog?: string }) => {
  return Brm.defensiveTechniqueApi.findDefensiveTechnique({ catalog });
};

interface IUseDefensiveTechniques {
  catalog?: string;
  options?: QueryOption<typeof getDefensiveTechniques>;
}

/**
 * React-query hook for getting all defensive techniques
 *
 * @param
 * @returns react-query for getDefendCatalogs
 */
export const useDefensiveTechniques = ({ catalog, options }: IUseDefensiveTechniques = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.defensiveTechniquesQueryKey.catalog(catalog),
    queryFn: () => getDefensiveTechniques({ catalog }),
  });
};
