import * as ReactQuery from "@tanstack/react-query";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./cci-controls-query-key-factory";

export const getCciControls = ({ catalog }: { catalog?: string }) => {
  return Brm.cciControlApi.findCciControl({ catalog });
};

interface IUseCciControls {
  catalog?: string;
  options?: QueryOption<typeof getCciControls>;
}

/**
 * React-query hook for getting all defend Catalogs
 *
 * @param
 * @returns react-query for getDefendCatalogs
 */
export const useCciControls = ({ catalog, options }: IUseCciControls = {}) => {
  return ReactQuery.useQuery({
    ...options,
    queryKey: KeyFactory.cciControlsQueryKey.catalog(catalog),
    queryFn: () => getCciControls({ catalog }),
  });
};
