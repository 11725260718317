import * as BrmGql from "generated/graphql";

export const targetKeys = {
  all: ["targets"] as const,
  element: (elementId: string | null, elementName: string | null, attackerTargetQuery: any) => [
    ...targetKeys.all,
    "elementTargets",
    elementId,
    elementName,
    attackerTargetQuery,
  ],
};

export const topicKeys = {
  all: ["topics"] as const,
  element: (elementId: string | null, elementName: string | null) => [
    ...topicKeys.all,
    "elementTopics",
    elementName,
    elementId,
  ],
};

export const allocatedControlKeys = {
  all: ["allocatedControls"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    variantId: string | undefined,
    targetAllocatedControlsData: BrmGql.GetTargetAllocatedControlsQuery | undefined,
    systemAssetAllocatedControlsQuery: BrmGql.GetSystemAssetAllocatedControlsQuery | undefined,
    systemAssetTypeAllocatedControlsQuery: BrmGql.GetSystemAssetTypeAllocatedControlsQuery | undefined,
    sctmAllocatedControlsQuery: BrmGql.GetSctmAllocatedControlsQuery | undefined
  ) => [
    ...allocatedControlKeys.all,
    elementId,
    elementName,
    variantId,
    targetAllocatedControlsData,
    systemAssetAllocatedControlsQuery,
    systemAssetTypeAllocatedControlsQuery,
    sctmAllocatedControlsQuery,
  ],
};

export const defensiveTechniqueKeys = {
  all: ["defensiveTechniques"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    variantId: string | undefined,
    targetAllocatedControlsData: BrmGql.GetTargetAllocatedControlsQuery | undefined,
    systemAssetAllocatedControlsQuery: BrmGql.GetSystemAssetAllocatedControlsQuery | undefined,
    systemAssetTypeAllocatedControlsQuery: BrmGql.GetSystemAssetTypeAllocatedControlsQuery | undefined,
    sctmAllocatedControlsQuery: BrmGql.GetSctmAllocatedControlsQuery | undefined
  ) => [
    ...defensiveTechniqueKeys.all,
    elementId,
    elementName,
    variantId,
    targetAllocatedControlsData,
    systemAssetAllocatedControlsQuery,
    systemAssetTypeAllocatedControlsQuery,
    sctmAllocatedControlsQuery,
  ],
};

export const digitalArtifactsKeys = {
  all: ["digitalArtifacts"] as const,
  element: (
    elementId: string | null,
    elementName: string | null,
    variantId: string | undefined,
    targetAllocatedControlsData: BrmGql.GetTargetAllocatedControlsQuery | undefined,
    systemAssetAllocatedControlsQuery: BrmGql.GetSystemAssetAllocatedControlsQuery | undefined,
    systemAssetTypeAllocatedControlsQuery: BrmGql.GetSystemAssetTypeAllocatedControlsQuery | undefined,
    sctmAllocatedControlsQuery: BrmGql.GetSctmAllocatedControlsQuery | undefined
  ) => [
    ...digitalArtifactsKeys.all,
    elementId,
    elementName,
    variantId,
    targetAllocatedControlsData,
    systemAssetAllocatedControlsQuery,
    systemAssetTypeAllocatedControlsQuery,
    sctmAllocatedControlsQuery,
  ],
};

export const activityKeys = {
  all: ["activities"] as const,
  element: (elementId: string, elementType: string, dataTypeData: any, missionData: any) => [
    ...activityKeys.all,
    elementId,
    elementType,
    dataTypeData,
    missionData,
  ],
};

export const vulnerabilityConditionKeys = {
  all: ["element"] as const,
};
