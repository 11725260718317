import Node from "brm/system-model/system-facts/Node";
import NodeFullDetails from "brm/system-model/system-facts/full-details/NodeFullDetails";
import Activity from "brm/system-model/system-facts/Activity";
import ActivityFullDetails from "brm/system-model/system-facts/full-details/ActivityFullDetails";
import Capability from "brm/system-model/system-facts/Capability";
import CapabilityFullDetails from "brm/system-model/system-facts/full-details/CapabilityFullDetails";
import Dataflow from "brm/system-model/system-facts/Dataflow";
import DataType from "brm/system-model/system-facts/Datatype";
import DatatypeFullDetails from "brm/system-model/system-facts/full-details/DatatypeFullDetails";
import DataflowFullDetails from "brm/system-model/system-facts/full-details/DataflowFullDetails";
import ElementCategory from "brm/system-model/system-facts/ElementCategory";
import StructuralCategory from "brm/system-model/system-facts/StructuralCategory";
import Exchange from "brm/system-model/system-facts/Exchange";
import ExchangeFullDetails from "brm/system-model/system-facts/full-details/ExchangeFullDetails";
import Mission from "brm/system-model/system-facts/Mission";
import MissionFullDetails from "brm/system-model/system-facts/full-details/MissionFullDetails";
import Person from "brm/system-model/system-facts/Person";
import PersonFullDetails from "brm/system-model/system-facts/full-details/PersonFullDetails";
import Resource from "brm/system-model/system-facts/Resource";
import ResourceFullDetails from "brm/system-model/system-facts/full-details/ResourceFullDetails";
/*  ================ SystemAsset Imports =========================  */
import SystemAsset from "brm/system-model/system-assets/SystemAsset";
import SystemAssetFullDetails from "brm/system-model/system-assets/full-details/SystemAssetFullDetails";
import SystemAssetType from "brm/system-model/system-assets/SystemAssetType";
import SystemAssetTypeFullDetails from "brm/system-model/system-assets/full-details/SystemAssetTypeFullDetails";
import SystemAssetCategory from "brm/system-model/system-assets/SystemAssetCategory";
/*  ================ categorization Imports =========================  */
import ClassifiedCategory from "brm/system-model/categorization/ClassificationCategoryList";
import SecurityObjective from "brm/system-model/categorization/SecurityObjectiveList";
import CategorizedEntity from "brm/system-model/categorization/CategorizedEntityList";
import SecurityObjectiveOverview from "brm/system-model/categorization/full-details/SecurityObjectiveFullDetails";
import CategorizedEntityOverview from "brm/system-model/categorization/full-details/CategorizedEntityFullDetails";
/*  ================ Access Imports =========================  */
import AccessPoint from "brm/system-model/access/AccessPoint";
import AccessPointDetails from "brm/system-model/access/full-details/AccessPointFullDetails";
import AccessPointCategory from "brm/system-model/access/AccessPointCategory";
import AttackSurface from "brm/system-model/access/AttackSurface";
import AttackSurfaceDetails from "brm/system-model/access/full-details/AttackSurfaceFullDetails";
import AttackSurfaceCategory from "brm/system-model/access/AttackSurfaceCategory";
import AttackVector from "brm/system-model/access/AttackVector";
import AttackVectorDetails from "brm/system-model/access/full-details/AttackVectorFullDetails";
import AttackVectorCategory from "brm/system-model/access/AttackVectorCategory";
import Boundary from "brm/system-model/access/Boundary";
import BoundaryActivities from "brm/system-model/access/BoundaryActivities";
import EntryPoint from "brm/system-model/access/EntryPoint";
import EntryPointDetails from "brm/system-model/access/full-details/EntryPointFullDetails";
import EntryPointCategory from "brm/system-model/access/EntryPointCategory";
/*  ================ Admin Imports =========================  */
import {
  Installations,
  InstallationOverview,
  Projects,
  ProjectOverview,
  Organizations,
  OrganizationOverview,
  Users,
  UserOverview,
  AddUserForm,
} from "features/admin";
/*  ================ Configuration Imports =========================  */
import { Metadata, Modules, ModuleOverview, SystemOverview, Systems } from "features/configuration";

/*  ================ TREV Imports =========================  */
import AttackerCategory from "brm/threat-model/AttackerCategory";
import Attacker from "brm/threat-model/Attacker";
import AttackerDetails from "brm/threat-model/full-details/AttackerFullDetails";
/*  ================ GLOBAL THREAT Imports =========================  */
import ThreatTier from "brm/threat-model/ThreatTier";
import ThreatTierDetails from "brm/threat-model/full-details/ThreatTierFullDetails";
import ThreatGroups from "brm/threat-model/ThreatGroups";

import { ThreatGroup } from "features/global-threat/routes/ThreatGroup";
import ThreatCatalog from "brm/threat-model/ThreatCatalog";
import ThreatCatalogDetails from "brm/threat-model/full-details/ThreatCatalogFullDetails";

import { ThreatCampaigns } from "features/global-threat/routes/ThreatCampaigns";
import { ThreatCampaign } from "features/global-threat/routes/ThreatCampaign";
import { ThreatSoftware } from "features/global-threat/routes/ThreatSoftware";
import { ThreatSoftwareId } from "features/global-threat/routes/ThreatSoftwareId";
import { ThreatDataSources } from "features/global-threat/routes/ThreatDataSources";
import { ThreatDataSource } from "features/global-threat/routes/ThreatDataSource";
import { OffensiveTactics } from "features/global-threat/routes/OffensiveTactics";
import { OffensiveTactic } from "features/global-threat/routes/OffensiveTactic";
import { OffensiveTechniques } from "features/global-threat/routes/OffensiveTechniques";
import { OffensiveTechnique } from "features/global-threat/routes/OffensiveTechnique";
import { TargetAssets } from "features/global-threat/routes/TargetAssets";
import { TargetAsset } from "features/global-threat/routes/TargetAsset";
import { AffectedPlatforms } from "features/global-threat/routes/AffectedPlatforms";
import { AffectedPlatform } from "features/global-threat/routes/AffectedPlatform";

import { DefendCatalogs } from "features/mitre-d3fend/routes/DefendCatalogs";
import { DefensiveTactics } from "features/mitre-d3fend/routes/DefensiveTactics";
import { DefensiveTechniques } from "features/mitre-d3fend/routes/DefensiveTechniques";
import { DigitalArtifacts } from "features/mitre-d3fend/routes/DigitalArtifacts";
import { CciControls } from "features/mitre-d3fend/routes/CciControls";
import { NistControls } from "features/mitre-d3fend/routes/NistControls";

/*  ================ Risk Imports =========================  */
import AttackKind from "brm/risk-model/attack/AttackKind";
import AttackKindFullDetails from "brm/risk-model/attack/full-details/AttackKindFullDetails";
import AttackMechanism from "brm/risk-model/attack/AttackMechanism";
import AttackMechanismFullDetails from "brm/risk-model/attack/full-details/AttackMechanismFullDetails";
import AttackTactic from "brm/risk-model/attack/AttackTactic";
import AttackTacticFullDetails from "brm/risk-model/attack/full-details/AttackTacticFullDetails";
import Attack from "brm/risk-model/attack/Attacks";
import AttackFullDetails from "brm/risk-model/attack/full-details/AttackFullDetails";
import { Risks } from "brm/risk-model/risk/Risks";
import { RiskFullDetails } from "brm/risk-model/risk/full-details/RiskFullDetails";
import RiskCategory from "brm/risk-model/risk/RiskCategory";
import ThreatEvent from "brm/risk-model/trev/ThreatEvents";
import ThreatEventFullDetails from "brm/risk-model/trev/full-details/ThreatEventFullDetails";
import ThreatEventCategory from "brm/risk-model/trev/ThreatEventCategory";
import ThreatEventPattern from "brm/risk-model/trev/ThreatEventPatterns";
import ThreatEventPatternFullDetails from "brm/risk-model/trev/full-details/ThreatEventPatternFullDetails";
import Harm from "brm/risk-model/impact/HarmList";
import UndesiredEvents from "brm/risk-model/impact/UndesiredEvents";
import UndesiredEventFullDetails from "brm/risk-model/impact/full-details/UndesiredEventFullDetails";
import Asset from "brm/risk-model/assets/AssetList";
import AssetFullDetails from "brm/risk-model/assets/full-details/AssetFullDetails";
import AssetCategory from "brm/risk-model/assets/AssetCategory";
import Vulnerability from "brm/risk-model/vulnerability/Vulnerability";
import VulnerabilityFullDetails from "brm/risk-model/vulnerability/full-details/VulnerabilityFullDetails";
import VulnerabilityCategory from "brm/risk-model/vulnerability/VulnerabilityCategory";
import SoftwareVulnerability from "brm/risk-model/vulnerability/SoftwareVulnerability";
import { CveFinding } from "brm/risk-model/vulnerability/CveFinding";
/*  ================ KB Imports =========================  */
// import KbProject from "brm/knowledgebase/KBProject";
// import KbOrganization from "brm/knowledgebase/KBOrganization";
// import KnowledgebaseList from "brm/knowledgebase/KnowledgebaseList";
// import KnowledgebaseListDetails from "brm/knowledgebase/full-details/KBFullDetails";
import {
  Knowledgebases,
  KnowledgebaseOverview,
  KnowledgebaseProjectConfiguration,
  KnowledgebaseOrganizationConfiguration,
} from "features/knowledgebase";
/*  ================ Mitigation Imports =========================  */
import {
  Variants,
  VariantOverview,
  AllocatedControls,
  AllocatedControlOverview,
  SctmOverview,
} from "features/mitigation";
/*  ================ GLobalCOntrol Imports =========================  */

import {
  ControlCatalogs,
  ControlCatalog,
  ControlFamilies,
  ControlFamily,
  ControlTypes,
  ControlType,
  StandardBaselines,
  StandardBaseline,
  ProjectCatalogConfiguration,
} from "features/global-control";

import EmptyContent from "brm/layout/Content/EmptyContent";
import { LicenseAlert } from "components/elements";

/*  ================ Calibration =========================  */
import { ImpactCalibration, LikelihoodCalibration } from "features/calibration";
import { Reports } from "features/reports/routes/Reports";

import { CONTENT_TYPE } from "atoms/atoms-content";
import { LandingPage } from "features/welcome";

import { Logout } from "features/brm";

// import { Help } from "features/help";

const contentMap = {
  /* ===== SystemFacts ==== */
  activities: {
    panelId: "activityPanel",
    tableId: "activityTable",
    Content: Activity,
    type: CONTENT_TYPE.table,
  },
  activityFullDetails: {
    panelId: "activityFullDetailsPanel",
    tableId: "activityFullDetailsTable",
    Content: ActivityFullDetails,
    type: CONTENT_TYPE.table,
  },
  capabilities: {
    panelId: "capabilityPanel",
    tableId: "capabilityTable",
    Content: Capability,
    type: CONTENT_TYPE.table,
  },
  capabilityFullDetails: {
    panelId: "capabilityFullDetailsPanel",
    tableId: "capabilityFullDetailsTable",
    Content: CapabilityFullDetails,
    type: CONTENT_TYPE.table,
  },
  nodes: {
    panelId: "nodePanel",
    tableId: "nodeTable",
    Content: Node,
    type: CONTENT_TYPE.table,
  },
  nodeFullDetails: {
    panelId: "nodeFullDetailsPanel",
    tableId: "nodeFullDetailsTable",
    Content: NodeFullDetails,
    type: CONTENT_TYPE.table,
  },
  dataflows: {
    panelId: "dataFlowPanel",
    tableId: "dataFlowTable",
    Content: Dataflow,
    type: CONTENT_TYPE.table,
  },
  datatypes: {
    panelId: "dataTypePanel",
    tableId: "dataTypeTable",
    Content: DataType,
    type: CONTENT_TYPE.table,
  },
  datatypeFullDetails: {
    panelId: "datatypeFullDetailsPanel",
    tableId: "datatypeFullDetailsTable",
    Content: DatatypeFullDetails,
    type: CONTENT_TYPE.table,
  },
  dataflowFullDetails: {
    panelId: "dataflowFullDetailsPanel",
    tableId: "dataflowFullDetailsTable",
    Content: DataflowFullDetails,
    type: CONTENT_TYPE.table,
  },
  elementCategories: {
    panelId: "elementCategoryPanel",
    tableId: "elementCategoryTable",
    Content: ElementCategory,
    type: CONTENT_TYPE.table,
  },
  structuralCategories: {
    panelId: "structuralCategoryPanel",
    tableId: "structuralCategoryTable",
    Content: StructuralCategory,
    type: CONTENT_TYPE.table,
  },
  exchanges: {
    panelId: "exchangePanel",
    tableId: "exchangeTable",
    Content: Exchange,
    type: CONTENT_TYPE.table,
  },
  exchangeFullDetails: {
    panelId: "exchangeFullDetailsPanel",
    tableId: "exchangeFullDetailsTable",
    Content: ExchangeFullDetails,
    type: CONTENT_TYPE.table,
  },

  missions: {
    panelId: "missionPanel",
    tableId: "missionTable",
    Content: Mission,
    type: CONTENT_TYPE.table,
  },
  missionFullDetails: {
    panelId: "missionFullDetailsPanel",
    tableId: "missionFullDetailsTable",
    Content: MissionFullDetails,
    type: CONTENT_TYPE.table,
  },
  persons: {
    panelId: "personPanel",
    tableId: "personTable",
    Content: Person,
    type: CONTENT_TYPE.table,
  },
  personFullDetails: {
    panelId: "personFullDetailsPanel",
    tableId: "personFullDetailsTable",
    Content: PersonFullDetails,
    type: CONTENT_TYPE.table,
  },
  resources: {
    panelId: "resourcePanel",
    tableId: "resourceTable",
    Content: Resource,
    type: CONTENT_TYPE.table,
  },
  resourceFullDetails: {
    panelId: "resourceFullDetailsPanel",
    tableId: "resourceFullDetailsTable",
    Content: ResourceFullDetails,
    type: CONTENT_TYPE.table,
  },
  /* ===== SystemAssets ==== */
  systemAssets: {
    panelId: "systemAssetPanel",
    tableId: "systemAssetTable",
    Content: SystemAsset,
    type: CONTENT_TYPE.table,
  },
  systemAssetFullDetails: {
    panelId: "systemAssetFullDetailsPanel",
    tableId: "systemAssetFullDetailsTable",
    Content: SystemAssetFullDetails,
    type: CONTENT_TYPE.table,
  },
  systemAssetCategories: {
    panelId: "systemAssetCategoryPanel",
    tableId: "systemAssetCategoryTable",
    Content: SystemAssetCategory,
    type: CONTENT_TYPE.table,
  },
  systemAssetTypes: {
    panelId: "systemAssetTypePanel",
    tableId: "systemAssetTypeTable",
    Content: SystemAssetType,
    type: CONTENT_TYPE.table,
  },
  systemAssetTypeFullDetails: {
    panelId: "systemAssetTypeFullDetailsPanel",
    tableId: "systemAssetTypeFullDetailsTable",
    Content: SystemAssetTypeFullDetails,
    type: CONTENT_TYPE.table,
  },
  /* ===== Catgorization ==== */
  classifiedCategories: {
    panelId: "classificationPanel",
    tableId: "classificationTable",
    Content: ClassifiedCategory,
    type: CONTENT_TYPE.table,
  },
  securityObjectives: {
    panelId: "securityObjectivePanel",
    tableId: "securityObjectiveTable",
    Content: SecurityObjective,
    type: CONTENT_TYPE.table,
  },
  categorizedEntities: {
    panelId: "categorizedEntityPanel",
    tableId: "categorizedEntityTable",
    Content: CategorizedEntity,
    type: CONTENT_TYPE.table,
  },
  securityObjectivesOverview: {
    panelId: "securityObjectivePanel",
    tableId: "securityObjectiveTable",
    Content: SecurityObjectiveOverview,
    type: CONTENT_TYPE.table,
  },
  categorizedEntitiesOverview: {
    panelId: "categorizedEntityPanel",
    tableId: "categorizedEntityTable",
    Content: CategorizedEntityOverview,
    type: CONTENT_TYPE.table,
  },
  /* ===== Access ==== */
  accessPoints: {
    panelId: "accessPointPanel",
    tableId: "accessPointTable",
    Content: AccessPoint,
    type: CONTENT_TYPE.table,
  },
  accessPointsDetails: {
    panelId: "accessPointPanelDetails",
    tableId: "accessPointTableDetails",
    Content: AccessPointDetails,
    type: CONTENT_TYPE.table,
  },
  accessPointCategories: {
    panelId: "accessPointCategoryPanel",
    tableId: "accessPointCategoryTable",
    Content: AccessPointCategory,
    type: CONTENT_TYPE.table,
  },
  attackSurfaces: {
    panelId: "attackSurfacePanel",
    tableId: "attackSurfaceTable",
    Content: AttackSurface,
    type: CONTENT_TYPE.table,
  },
  attackSurfacesDetails: {
    panelId: "attackSurfacePanel",
    tableId: "attackSurfaceTable",
    Content: AttackSurfaceDetails,
    type: CONTENT_TYPE.table,
  },
  attackSurfaceCategories: {
    panelId: "attackSurfaceCategoryPanel",
    tableId: "attackSurfaceCategoryTable",
    Content: AttackSurfaceCategory,
    type: CONTENT_TYPE.table,
  },

  attackVectors: {
    panelId: "attackVectorPanel",
    tableId: "attackVectorsTable",
    Content: AttackVector,
    type: CONTENT_TYPE.table,
  },
  attackVectorsDetails: {
    panelId: "attackVectorPanelDetails",
    tableId: "attackVectorsTableDetails",
    Content: AttackVectorDetails,
    type: CONTENT_TYPE.table,
  },
  attackVectorCategories: {
    panelId: "attackVectorCategoryPanel",
    tableId: "attackVectorCategoryTable",
    Content: AttackVectorCategory,
    type: CONTENT_TYPE.table,
  },
  boundaries: {
    panelId: "boundaryPanel",
    tableId: "boundaryTable",
    Content: Boundary,
    type: CONTENT_TYPE.table,
  },
  boundariesActivities: {
    panelId: "boundaryActivitiesPanel",
    tableId: "boundaryActivitiesTable",
    Content: BoundaryActivities,
    type: CONTENT_TYPE.table,
  },
  entryPoints: {
    panelId: "entryPointPanel",
    tableId: "entryPointTable",
    Content: EntryPoint,
    type: CONTENT_TYPE.table,
  },
  entryPointsDetails: {
    panelId: "entryPointPanel",
    tableId: "entryPointTable",
    Content: EntryPointDetails,
    type: CONTENT_TYPE.table,
  },
  entryPointCategories: {
    panelId: "entryPointCategoryPanel",
    tableId: "entryPointCategoryTable",
    Content: EntryPointCategory,
    type: CONTENT_TYPE.table,
  },
  /* ===== Admin ==== */
  installation: {
    panelId: "installationPanel",
    tableId: "installationTable",
    Content: Installations,
    type: CONTENT_TYPE.table,
  },
  organizations: {
    panelId: "organizationPanel",
    tableId: "organizationTable",
    Content: Organizations,
    type: CONTENT_TYPE.table,
  },
  projects: {
    panelId: "projectPanel",
    tableId: "projectTable",
    Content: Projects,
    type: CONTENT_TYPE.table,
  },
  users: {
    panelId: "userPanel",
    tableId: "userTable",
    Content: Users,
    type: CONTENT_TYPE.table,
  },
  projectOverview: {
    panelId: "projectOverviewPanel",
    tableId: "projectOverviewTable",
    Content: ProjectOverview,
    type: CONTENT_TYPE.table,
  },
  organizationOverview: {
    panelId: "organizationOverviewPanel",
    tableId: "organizationOverviewTable",
    Content: OrganizationOverview,
    type: CONTENT_TYPE.table,
  },
  installationOverview: {
    panelId: "installationOverviewPanel",
    tableId: "installationOverviewTable",
    Content: InstallationOverview,
    type: CONTENT_TYPE.table,
  },
  userOverview: {
    panelId: "userOverviewPanel",
    tableId: "userOverviewTable",
    Content: UserOverview,
    type: CONTENT_TYPE.table,
  },
  addUsers: {
    panelId: "userAddPanel",
    tableId: "userAddTable",
    Content: AddUserForm,
    type: CONTENT_TYPE.table,
  },
  /* ===== Configuration ==== */
  metadata: {
    panelId: "metadataPanel",
    tableId: "metadataTable",
    Content: Metadata,
    type: CONTENT_TYPE.table,
  },
  modules: {
    panelId: "modulesPanel",
    tableId: "modulesTable",
    Content: Modules,
    type: CONTENT_TYPE.table,
  },
  modulesDetails: {
    panelId: "modulesPanelDetails",
    tableId: "modulesTableDetails",
    Content: ModuleOverview,
    type: CONTENT_TYPE.table,
  },
  systems: {
    panelId: "systemsPanel",
    tableId: "systemsTable",
    Content: Systems,
    type: CONTENT_TYPE.table,
  },
  systemsDetails: {
    panelId: "systemsPanel",
    tableId: "systemsTable",
    Content: SystemOverview,
    type: CONTENT_TYPE.table,
  },
  /* ===== Configuration ==== */
  attackKinds: {
    panelId: "attackKindsPanel",
    tableId: "attackKindsTable",
    Content: AttackKind,
    type: CONTENT_TYPE.table,
  },
  attackKindFullDetails: {
    panelId: "attackKindFullDetailsPanel",
    tableId: "attackKindDetailsTable",
    Content: AttackKindFullDetails,
    type: CONTENT_TYPE.table,
  },
  attackMechanisms: {
    panelId: "attackMechanismsPanel",
    tableId: "attackMechanismsTable",
    Content: AttackMechanism,
    type: CONTENT_TYPE.table,
  },
  attackMechanismFullDetails: {
    panelId: "attackMechanismFullDetailsPanel",
    tableId: "attackMechanismDetailsTable",
    Content: AttackMechanismFullDetails,
    type: CONTENT_TYPE.table,
  },
  attackTactics: {
    panelId: "attackTacticsPanel",
    tableId: "attackTacticsTable",
    Content: AttackTactic,
    type: CONTENT_TYPE.table,
  },
  attackTacticFullDetails: {
    panelId: "attackTacticFullDetailsPanel",
    tableId: "attackTacticDetailsTable",
    Content: AttackTacticFullDetails,
    type: CONTENT_TYPE.table,
  },
  attackers: {
    panelId: "attackersPanel",
    tableId: "attackersTable",
    Content: Attacker,
    type: CONTENT_TYPE.table,
  },
  attackersDetails: {
    panelId: "attackersPanelDetails",
    tableId: "attackersTableDetails",
    Content: AttackerDetails,
    type: CONTENT_TYPE.table,
  },
  attackerCategories: {
    panelId: "attackerCategoriesPanel",
    tableId: "attackerCategoriesTable",
    Content: AttackerCategory,
    type: CONTENT_TYPE.table,
  },
  threatTiers: {
    panelId: "threatTiersPanel",
    tableId: "threatTiersTable",
    Content: ThreatTier,
    type: CONTENT_TYPE.table,
  },
  threatTiersDetails: {
    panelId: "threatTiersPanelDetails",
    tableId: "threatTiersTableDetails",
    Content: ThreatTierDetails,
    type: CONTENT_TYPE.table,
  },
  threatGroups: {
    panelId: "threatGroupsPanel",
    tableId: "threatGroupsTable",
    Content: ThreatGroups,
    type: CONTENT_TYPE.table,
  },
  threatGroupDetails: {
    panelId: "threatGroupPanelDetails",
    tableId: "threatGroupTableDetails",
    Content: ThreatGroup,
    type: CONTENT_TYPE.table,
  },
  threatCatalogs: {
    panelId: "threatCatalogsPanel",
    tableId: "threatCatalogsTable",
    Content: ThreatCatalog,
    type: CONTENT_TYPE.table,
  },
  threatCatalogsDetails: {
    panelId: "threatCatalogsPanelDetails",
    tableId: "threatCatalogsTableDetails",
    Content: ThreatCatalogDetails,
    type: CONTENT_TYPE.table,
  },
  threatCampaigns: {
    panelId: "threatCampaignsPanel",
    tableId: "threatCampaignsTable",
    Content: ThreatCampaigns,
    type: CONTENT_TYPE.table,
  },
  threatCampaign: {
    panelId: "threatCampaignPanel",
    tableId: "threatCampaignTable",
    Content: ThreatCampaign,
    type: CONTENT_TYPE.table,
  },
  threatSoftware: {
    panelId: "threatSoftwarePanel",
    tableId: "threatSoftwareTable",
    Content: ThreatSoftware,
    type: CONTENT_TYPE.table,
  },
  threatSoftwareId: {
    panelId: "threatSoftwareIdPanel",
    tableId: "threatSoftwareIdTable",
    Content: ThreatSoftwareId,
    type: CONTENT_TYPE.table,
  },
  threatDataSources: {
    panelId: "threatDataSourcesPanel",
    tableId: "threatDataSourcesTable",
    Content: ThreatDataSources,
    type: CONTENT_TYPE.table,
  },
  threatDataSource: {
    panelId: "threatDataSourcePanel",
    tableId: "threatDataSourceTable",
    Content: ThreatDataSource,
    type: CONTENT_TYPE.table,
  },
  offensiveTactics: {
    panelId: "offensiveTacticsPanel",
    tableId: "offensiveTacticsTable",
    Content: OffensiveTactics,
    type: CONTENT_TYPE.table,
  },
  offensiveTactic: {
    panelId: "offensiveTacticPanel",
    tableId: "offensiveTacticTable",
    Content: OffensiveTactic,
    type: CONTENT_TYPE.table,
  },
  offensiveTechniques: {
    panelId: "offensiveTechniquesPanel",
    tableId: "offensiveTechniquesTable",
    Content: OffensiveTechniques,
    type: CONTENT_TYPE.table,
  },
  offensiveTechnique: {
    panelId: "offensiveTechniquePanel",
    tableId: "offensiveTechniqueTable",
    Content: OffensiveTechnique,
    type: CONTENT_TYPE.table,
  },
  affectedPlatforms: {
    panelId: "affectedPlatformsPanel",
    tableId: "affectedPlatformsTable",
    Content: AffectedPlatforms,
    type: CONTENT_TYPE.table,
  },
  affectedPlatform: {
    panelId: "affectedPlatformPanel",
    tableId: "affectedPlatformTable",
    Content: AffectedPlatform,
    type: CONTENT_TYPE.table,
  },
  targetAsset: {
    panelId: "targetAssetPanel",
    tableId: "targetAssetTable",
    Content: TargetAsset,
    type: CONTENT_TYPE.table,
  },
  targetAssets: {
    panelId: "targetAssetsPanel",
    tableId: "targetAssetsTable",
    Content: TargetAssets,
    type: CONTENT_TYPE.table,
  },
  defendCatalog: {
    panelId: "mitreD3fendPanel",
    tableId: "mitreD3fendTable",
    Content: DefendCatalogs,
    type: CONTENT_TYPE.table,
  },
  defensiveTactics: {
    panelId: "defensiveTacticsPanel",
    tableId: "defensiveTacticsTable",
    Content: DefensiveTactics,
    type: CONTENT_TYPE.table,
  },
  defensiveTechniques: {
    panelId: "defensiveTechniquesPanel",
    tableId: "defensiveTechniquesTable",
    Content: DefensiveTechniques,
    type: CONTENT_TYPE.table,
  },
  digitalArtifacts: {
    panelId: "digitalArtifactsPanel",
    tableId: "digitalArtifactsTable",
    Content: DigitalArtifacts,
    type: CONTENT_TYPE.table,
  },
  cciControls: {
    panelId: "cciControlsPanel",
    tableId: "cciControlsTable",
    Content: CciControls,
    type: CONTENT_TYPE.table,
  },
  nistControls: {
    panelId: "nistControlsPanel",
    tableId: "nistControlsTable",
    Content: NistControls,
    type: CONTENT_TYPE.table,
  },
  /* ===== Risk Model ==== */
  attacks: {
    panelId: "attackPanel",
    tableId: "attackTable",
    Content: Attack,
    type: CONTENT_TYPE.table,
  },
  attackFullDetails: {
    panelId: "attackFullDetailsPanel",
    tableId: "attackDetailsTable",
    Content: AttackFullDetails,
    type: CONTENT_TYPE.table,
  },
  risks: {
    panelId: "riskPanel",
    tableId: "riskTable",
    Content: Risks,
    type: CONTENT_TYPE.table,
  },
  riskFullDetails: {
    panelId: "riskFullDetailsPanel",
    tableId: "riskFullDetailsTable",
    Content: RiskFullDetails,
    type: CONTENT_TYPE.table,
  },
  riskCategories: {
    panelId: "riskCategoryPanel",
    tableId: "riskCategoryTable",
    Content: RiskCategory,
    type: CONTENT_TYPE.table,
  },
  threatEvents: {
    panelId: "threateventPanel",
    tableId: "threateventTable",
    Content: ThreatEvent,
    type: CONTENT_TYPE.table,
  },
  threatEventFullDetails: {
    panelId: "threatEventFullDetailsPanel",
    tableId: "threatEventFullDetailsTable",
    Content: ThreatEventFullDetails,
    type: CONTENT_TYPE.table,
  },
  threatEventCategories: {
    panelId: "threateventCategoryPanel",
    tableId: "threateventCategoryTable",
    Content: ThreatEventCategory,
    type: CONTENT_TYPE.table,
  },
  threatEventPatterns: {
    panelId: "threateventPatternPanel",
    tableId: "threateventPatternTable",
    Content: ThreatEventPattern,
    type: CONTENT_TYPE.table,
  },
  threatEventPatternFullDetails: {
    panelId: "threatEventPatternFullDetailsPanel",
    tableId: "threatEventPatternFullDetailsTable",
    Content: ThreatEventPatternFullDetails,
    type: CONTENT_TYPE.table,
  },
  assets: {
    panelId: "assetPanel",
    tableId: "assetTable",
    Content: Asset,
    type: CONTENT_TYPE.table,
  },
  assetFullDetails: {
    panelId: "assetFullDetailsPanel",
    tableId: "assetFullDetailsTable",
    Content: AssetFullDetails,
    type: CONTENT_TYPE.table,
  },
  assetCategories: {
    panelId: "assetCategoryPanel",
    tableId: "assetCategoryTable",
    Content: AssetCategory,
    type: CONTENT_TYPE.table,
  },
  harms: {
    panelId: "harmPanel",
    tableId: "harmTable",
    Content: Harm,
    type: CONTENT_TYPE.table,
  },
  undesiredEvents: {
    panelId: "undesiredEventsPanel",
    tableId: "undesiredEventsTable",
    Content: UndesiredEvents,
    type: CONTENT_TYPE.table,
  },
  undesiredEventFullDetails: {
    panelId: "undesiredEventFullDetailsPanel",
    tableId: "undesiredEventFullDetailsTable",
    Content: UndesiredEventFullDetails,
    type: CONTENT_TYPE.table,
  },
  vulnerabilities: {
    panelId: "vulnerabilityPanel",
    tableId: "vulnerabilityTable",
    Content: Vulnerability,
    type: CONTENT_TYPE.table,
  },
  vulnerabilityFullDetails: {
    panelId: "vulnerabilityFullDetailsPanel",
    tableId: "vulnerabilityFullDetailsTable",
    Content: VulnerabilityFullDetails,
    type: CONTENT_TYPE.table,
  },
  vulnerabilityCategories: {
    panelId: "vulnerabilityCategoryPanel",
    tableId: "vulnerabilityCategoryTable",
    Content: VulnerabilityCategory,
    type: CONTENT_TYPE.table,
  },
  softwareVulnerabilities: {
    panelId: "softwareVulnerabilityPanel",
    tableId: "softwareVulnerabilityTable",
    Content: SoftwareVulnerability,
    type: CONTENT_TYPE.table,
  },
  cveFindings: {
    panelId: "cveFindingPanel",
    tableId: "cveFindingTable",
    Content: CveFinding,
    type: CONTENT_TYPE.table,
  },
  /* ===== KB Model ==== */
  kbProjects: {
    panelId: "kbProjectPanel",
    tableId: "kbProjectTable",
    Content: KnowledgebaseProjectConfiguration,
    type: CONTENT_TYPE.table,
  },
  kbOrganizations: {
    panelId: "kbOrganizationPanel",
    tableId: "kbOrganizationTable",
    Content: KnowledgebaseOrganizationConfiguration,
    type: CONTENT_TYPE.table,
  },
  knowledgebases: {
    panelId: "knowledgebasePanel",
    tableId: "knowledgebaseTable",
    Content: Knowledgebases,
    type: CONTENT_TYPE.table,
  },
  knowledgebasesDetails: {
    panelId: "knowledgebasePanelDetails",
    tableId: "knowledgebaseTableDetails",
    Content: KnowledgebaseOverview,
    type: CONTENT_TYPE.table,
  },
  /* ===== Mitigation Model ==== */
  allocatedControls: {
    panelId: "allocatedControlPanel",
    tableId: "allocatedControlsTable",
    Content: AllocatedControls,
    type: CONTENT_TYPE.table,
  },
  allocatedControlsDetails: {
    panelId: "allocatedControlPanelDetails",
    tableId: "allocatedControlsTableDetails",
    Content: AllocatedControlOverview,
    type: CONTENT_TYPE.table,
  },
  sctmsDetails: {
    panelId: "sctmPanelDetails",
    tableId: "sctmTableDetails",
    Content: SctmOverview,
    type: CONTENT_TYPE.table,
  },
  variants: {
    panelId: "variantsPanel",
    tableId: "variantsTable",
    Content: Variants,
    type: CONTENT_TYPE.table,
  },
  variantsDetails: {
    panelId: "variantsPanelDetails",
    tableId: "variantsTableDetails",
    Content: VariantOverview,
    type: CONTENT_TYPE.table,
  },
  /* ===== Global Control Model ==== */
  controlCatalogs: {
    panelId: "controlcatalogsPanel",
    tableId: "controlcatalogsTable",
    Content: ControlCatalogs,
    type: CONTENT_TYPE.table,
  },
  controlCatalogsDetails: {
    panelId: "controlcatalogsPanelDetails",
    tableId: "controlcatalogsTableDetails",
    Content: ControlCatalog,
    type: CONTENT_TYPE.table,
  },
  controlFamily: {
    panelId: "controlfamilyPanel",
    tableId: "controlfamiliyTable",
    Content: ControlFamilies,
    type: CONTENT_TYPE.table,
  },
  controlFamilyFullDetails: {
    panelId: "controlFamilyFullDetailsPanel",
    tableId: "controlFamilyFullDetailsTable",
    Content: ControlFamily,
    type: CONTENT_TYPE.table,
  },
  controlType: {
    panelId: "controltypesPanel",
    tableId: "controltypesTable",
    Content: ControlTypes,
    type: CONTENT_TYPE.table,
  },
  controlTypeFullDetails: {
    panelId: "controlTypeFullDetailsPanel",
    tableId: "controlTypeFullDetailsTable",
    Content: ControlType,
    type: CONTENT_TYPE.table,
  },
  standardBaselines: {
    panelId: "standardBaselinesPanel",
    tableId: "standardBaselinesTable",
    Content: StandardBaselines,
    type: CONTENT_TYPE.table,
  },
  standardBaselineFullDetails: {
    panelId: "standardBaselineFullDetailsPanel",
    tableId: "standardBaselineFullDetailsTable",
    Content: StandardBaseline,
    type: CONTENT_TYPE.table,
  },
  projectCatalogConfig: {
    panelId: "projectCatalogConfigPanel",
    tableId: "projectCatalogConfigTable",
    Content: ProjectCatalogConfiguration,
    type: CONTENT_TYPE.table,
  },

  home: {
    panelId: "homePanel",
    tableId: "homeTable",
    Content: LandingPage,
    type: CONTENT_TYPE.general,
    hideBreadCrum: true,
    hidePrevNextBtn: true,
  },
  empty: {
    panelId: "",
    tableId: "",
    Content: EmptyContent,
    type: CONTENT_TYPE.general,
  },
  impactCalibration: {
    panelId: "impactCalibrationPanel",
    tableId: "impactCalibrationTable",
    Content: ImpactCalibration,
    type: CONTENT_TYPE.table,
  },
  likelihoodCalibration: {
    panelId: "likelihoodCalibrationPanel",
    tableId: "likelihoodCalibrationTable",
    Content: LikelihoodCalibration,
    type: CONTENT_TYPE.table,
  },
  reports: {
    panelId: "reportsPanel",
    tableId: "reportsPanel",
    Content: Reports,
    type: CONTENT_TYPE.table,
  },

  licenseError: {
    panelId: "licenseErrorPanel",
    tableId: "licenseErrorPanel",
    Content: LicenseAlert,
  },
  logout: {
    panelId: "logout",
    tableId: "logout",
    Content: Logout,
  },
  /* help: {
    panelId: "helpPanel",
    tableId: "helpTable",
    Content: Help,
    type: CONTENT_TYPE.table,
    hideBreadCrum: true,
    hidePrevNextBtn: true,
  }, */
};

export default contentMap;
