export const enum RoutePath {
  Home = "/brm/main/",
  Diagrams = "/brm/main/diagrams/*",
  SystemDiagram = "/brm/main/diagrams/system",
  SystemDiagramRelative = "system",
  AttackDiagram = "/brm/main/diagrams/attack",
  AttackDiagramRelative = "attack",
  ContextDiagram = "/brm/main/diagrams/context",
  ContextDiagramRelative = "context",
  Help = "/brm/helpcenter/Help",
  Demo = "/brm/helpcenter/demo",
  ThreatModels = "/brm/main/ThreatModel/*",
  GlobalThreatModels = "/brm/main/ThreatModel/Global/*",
  LocalThreatModels = "/brm/main/ThreatModel/Local/*",
  ThreatCatalogs = "/brm/main/ThreatModel/Global/ThreatCatalogs/",
  ThreatCatalog = "/brm/main/ThreatModel/Global/ThreatCatalogs/:id",
  ThreatTiers = "/brm/main/ThreatModel/Global/ThreatTier",
  ThreatTier = "/brm/main/ThreatModel/Global/ThreatTierDetails/:id",
  ThreatGroups = "/brm/main/ThreatModel/Global/ThreatGroups",
  ThreatGroup = "/brm/main/ThreatModel/Global/ThreatGroups/:id",
  ThreatCampaigns = "/brm/main/ThreatModel/Global/ThreatCampaigns",
  ThreatCampaign = "/brm/main/ThreatModel/Global/ThreatCampaigns/:id",
  ThreatSoftware = "/brm/main/ThreatModel/Global/ThreatSoftware",
  ThreatSoftwareId = "/brm/main/ThreatModel/Global/ThreatSoftware/:id",
  ThreatDataSources = "/brm/main/ThreatModel/Global/ThreatDataSources",
  ThreatDataSource = "/brm/main/ThreatModel/Global/ThreatDataSources/:id",
  OffensiveTactics = "/brm/main/ThreatModel/Global/OffensiveTactics",
  OffensiveTactic = "/brm/main/ThreatModel/Global/OffensiveTactics/:id",
  OffensiveTechniques = "/brm/main/ThreatModel/Global/OffensiveTechniques",
  OffensiveTechnique = "/brm/main/ThreatModel/Global/OffensiveTechniques/:id",
  AffectedPlatforms = "/brm/main/ThreatModel/Global/AffectedPlatforms",
  AffectedPlatform = "/brm/main/ThreatModel/Global/AffectedPlatforms/:id",
  TargetAssets = "/brm/main/ThreatModel/Global/TargetAssets",
  TargetAsset = "/brm/main/ThreatModel/Global/TargetAssets/:id",
  DefendCatalogs = "/brm/main/ThreatModel/Global/DefendCatalogs",
  DefendCatalog = "/brm/main/ThreatModel/Global/DefendCatalogs/:id",
  DefensiveTactics = "/brm/main/ThreatModel/Global/DefensiveTactics",
  DefensiveTactic = "/brm/main/ThreatModel/Global/DefensiveTactics:/id",
  DefensiveTechniques = "/brm/main/ThreatModel/Global/DefensiveTechniques",
  DefensiveTechnique = "/brm/main/ThreatModel/Global/DefensiveTechniques/:id",
  DigitalArtifacts = "/brm/main/ThreatModel/Global/DigitalArtifacts",
  DigitalArtifact = "/brm/main/ThreatModel/Global/DigitalArtifacts/:id",
  CciControls = "/brm/main/ThreatModel/Global/CciControls",
  CciControl = "/brm/main/ThreatModel/Global/CciControls/:id",
  NistControls = "/brm/main/ThreatModel/Global/NistControls",
  NistControl = "/brm/main/ThreatModel/Global/NistControls/:id",
  AttackModel = "/brm/main/attackModel/*",
  AttackMechanisms = "/brm/main/AttackModel/AttackMechanism",
  AttackMechanism = "/brm/main/AttackModel/AttackMechanismDetails/:id",
  AttackTactics = "/brm/main/AttackModel/AttackTactic",
  AttackTactic = "/brm/main/AttackModel/AttackTacticDetails/:id",
  AttackKinds = "/brm/main/AttackModel/AttackKind",
  AttackKind = "/brm/main/AttackModel/AttackKindDetails/:id",
  Attacks = "/brm/main/AttackModel/Attack",
  Attack = "/brm/main/AttackModel/AttackDetails/:id",
  AttackerCategories = "/brm/main/ThreatModel/Local/AttackerCategory",
  AttackerCategory = "/brm/main/ThreatModel/Local/AttackerCategoryDetails/:id",
  Attackers = "/brm/main/ThreatModel/Local/Attacker",
  Attacker = "/brm/main/ThreatModel/Local/AttackerDetails/:id",
  SystemModel = "/brm/main/SystemModel/*",
  Facts = "/brm/main/SystemModel/Facts/*",
  StructuralCategories = "/brm/main/SystemModel/Facts/StructuralCategories",
  StructuralCategory = "/brm/main/SystemModel/Facts/StructuralCategoryDetails/:id",
  ElementCategories = "/brm/main/SystemModel/Facts/ElementCategories",
  ElementCategory = "/brm/main/SystemModel/Facts/ElementCategoryDetails/:id",
  Nodes = "/brm/main/SystemModel/Facts/Nodes",
  Node = "/brm/main/SystemModel/Facts/NodeDetails/:id",
  Bus = "/brm/main/SystemModel/Facts/BusDetails/:id",
  Links = "/brm/main/SystemModel/Facts/Links",
  Link = "/brm/main/SystemModel/Facts/LinkDetails/:id",
  Groups = "/brm/main/SystemModel/Facts/Groups",
  Channels = "/brm/main/SystemModel/Facts/Channels",
  Exchanges = "/brm/main/SystemModel/Facts/Exchanges",
  Exchange = "/brm/main/SystemModel/Facts/ExchangeDetails/:id",
  Datatypes = "/brm/main/SystemModel/Facts/DataTypes",
  Datatype = "/brm/main/SystemModel/Facts/DataTypeDetails/:id",
  Persons = "/brm/main/SystemModel/Facts/Persons",
  Person = "/brm/main/SystemModel/Facts/PersonDetails/:id",
  Resources = "/brm/main/SystemModel/Facts/Resources",
  Resource = "/brm/main/SystemModel/Facts/ResourceDetails/:id",
  Dataflows = "/brm/main/SystemModel/Facts/Dataflows",
  Dataflow = "/brm/main/SystemModel/Facts/DataflowDetails/:id",
  Capabilities = "/brm/main/SystemModel/Facts/Capabilities",
  Capability = "/brm/main/SystemModel/Facts/CapabilityDetails/:id",
  Activities = "/brm/main/SystemModel/Facts/Activities",
  Activity = "/brm/main/SystemModel/Facts/ActivityDetails/:id",
  Missions = "/brm/main/SystemModel/Facts/Missions",
  Mission = "/brm/main/SystemModel/Facts/MissionDetails/:id",
  Access = "/brm/main/SystemModel/Access/*",
  EntryPoints = "/brm/main/SystemModel/Access/EntryPoint",
  EntryPoint = "/brm/main/SystemModel/Access/EntryPointDetails/:id",
  EntryPointCategories = "/brm/main/SystemModel/Access/EntryPointCategory",
  EntryPointCategory = "/brm/main/SystemModel/Access/EntryPointCategoryDetails/:id",
  AccessPointCategories = "/brm/main/SystemModel/Access/AccessPointCategory",
  AccessPointCategory = "/brm/main/SystemModel/Access/AccessPointCategoryDetails/:id",
  AccessPoints = "/brm/main/SystemModel/Access/AccessPoint",
  AccessPoint = "/brm/main/SystemModel/Access/AccessPointDetails/:id",
  AttackSurfaces = "/brm/main/SystemModel/Access/AttackSurface",
  AttackSurface = "/brm/main/SystemModel/Access/AttackSurfaceDetails/:id",
  AttackSurfaceCategories = "/brm/main/SystemModel/Access/AttackSurfaceCategory",
  AttackSurfaceCategory = "/brm/main/SystemModel/Access/AttackSurfaceCategoryDetails/:id",
  AttackVectorCategories = "/brm/main/SystemModel/Access/AttackVectorCategory",
  AttackVectorCategory = "/brm/main/SystemModel/Access/AttackVectorCategoryDetails/:id",
  AttackVectors = "/brm/main/SystemModel/Access/AttackVector",
  AttackVector = "/brm/main/SystemModel/Access/AttackVectorDetails/:id",
  Boundaries = "/brm/main/SystemModel/Access/Boundary",
  BoundariesActivities = "/brm/main/SystemModel/Access/BoundaryActivity",
  Categorization = "/brm/main/SystemModel/Categorization/*",
  ClassificationCategories = "/brm/main/SystemModel/Categorization/ClassificationCategory",
  ClassificationCategory = "/brm/main/SystemModel/Categorization/ClassificationCategoryOverview/:id",
  SecurityObjectives = "/brm/main/SystemModel/Categorization/SecurityObjective",
  SecurityObjective = "/brm/main/SystemModel/Categorization/SecurityObjectiveOverview/:id",
  CategorizedEntities = "/brm/main/SystemModel/Categorization/CategorizedEntity",
  CategorizedEntity = "/brm/main/SystemModel/Categorization/CategorizedEntityOverview/:id",
  SystemAssets = "/brm/main/SystemModel/SystemAsset",
  SystemAsset = "/brm/main/SystemModel/SystemAssetDetails/:id",
  SystemAssetCategories = "/brm/main/SystemModel/SystemAssetCategory",
  SystemAssetCategory = "/brm/main/SystemModel/SystemAssetCategoryDetails/:id",
  SystemAssetTypes = "/brm/main/SystemModel/SystemAssetType",
  SystemAssetType = "/brm/main/SystemModel/SystemAssetTypeDetails/:id",
  RiskModel = "/brm/main/RiskModel/*",
  ThreatEventPatterns = "/brm/main/RiskModel/ThreatEventPattern",
  ThreatEventPattern = "/brm/main/RiskModel/ThreatEventPatternDetails/:id",
  ThreatEventCategories = "/brm/main/RiskModel/ThreatEventCategory",
  ThreatEventCategory = "/brm/main/RiskModel/ThreatEventCategoryDetails/:id",
  ThreatEvents = "/brm/main/RiskModel/ThreatEvent",
  ThreatEvent = "/brm/main/RiskModel/ThreatEventDetails/:id",
  RiskCategories = "/brm/main/RiskModel/RiskCat",
  RiskCategory = "/brm/main/RiskModel/RiskCategoryDetails/:id",
  Risks = "/brm/main/RiskModel/RiskList",
  Risk = "/brm/main/RiskModel/RiskDetails/:id",
  Harms = "/brm/main/RiskModel/Harm",
  Harm = "/brm/main/RiskModel/HarmCategoryDetails/:id",
  UndesiredEvents = "/brm/main/RiskModel/UndesiredEvent",
  UndesiredEvent = "/brm/main/RiskModel/UndesiredEventDetails/:id",
  AssetCategories = "/brm/main/RiskModel/AssetCategories",
  AssetCategory = "/brm/main/RiskModel/AssetCategoryDetails/:id",
  Assets = "/brm/main/RiskModel/AssetList",
  Asset = "/brm/main/RiskModel/AssetDetails/:id",
  Vulnerabilities = "/brm/main/RiskModel/Vulnerability",
  Vulnerability = "/brm/main/RiskModel/VulnerabilityDetails/:id",
  SoftwareVulnerabilities = "/brm/main/RiskModel/SoftwareVulnerability",
  VulnerabilityCategories = "/brm/main/RiskModel/VulnerabilityCategory",
  VulnerabilityCategory = "/brm/main/RiskModel/VulnerabilityCategoryDetails/:id",
  CveFindings = "/brm/main/RiskModel/CveFinding",
  Mitigations = "/brm/main/Mitigation/*",
  AllocatedControls = "/brm/main/Mitigation/AllocatedControls",
  AllocatedControl = "/brm/main/Mitigation/AllocatedControlsDetails/:id",
  Sctm = "/brm/main/Mitigation/SCTMDetails",
  Variants = "/brm/main/Mitigation/Variant",
  Variant = "/brm/main/Mitigation/VariantDetails/:id",
  GlobalControl = "/brm/main/GlobalControl/*",
  ControlCatalogs = "/brm/main/GlobalControl/Controlcatalog",
  ControlCatalog = "/brm/main/GlobalControl/ControlcatalogDetails/:id",
  ControlFamilies = "/brm/main/GlobalControl/ControlFamily",
  ControlFamily = "/brm/main/GlobalControl/ControlFamilyDetails/:id",
  ControlTypes = "/brm/main/GlobalControl/ControlType",
  ControlType = "/brm/main/GlobalControl/ControlTypeDetails/:id",
  StandardBaselines = "/brm/main/GlobalControl/StandardBaseline",
  StandardBaseline = "/brm/main/GlobalControl/StandardBaselineDetails/:id",
  ProjectCatalogConfig = "/brm/main/ProjectCatalog",
  Kb = "/brm/main/Knowledgebase/*",
  Knowledgebases = "/brm/main/Knowledgebase/KBList",
  Knowledgebase = "/brm/main/Knowledgebase/KBListDetails/:id",
  KbOrganizations = "/brm/main/Knowledgebase/KBOrganization",
  KbProjects = "/brm/main/Knowledgebase/KBProject",
  Configuration = "/brm/main/Configuration/*",
  Metadata = "/brm/main/Configuration/Metadata",
  Modules = "/brm/main/Configuration/Module",
  Module = "/brm/main/Configuration/ModuleDetails/:id",
  Systems = "/brm/main/Configuration/System",
  System = "/brm/main/Configuration/SystemDetails/:id",
  Admin = "admin/*",
  Projects = "/brm/main/ProjectList",
  Project = "/brm/main/ProjectOverview/:id",
  ProjectOverview = "/brm/main/ProjectOverview/",
  Organizations = "/brm/main/Organizations",
  Organization = "/brm/main/OrganizationOverview/:id",
  Installations = "/brm/main/Installation",
  Installation = "/brm/main/InstallationOverview/:id",
  Users = "/brm/main/Users",
  User = "/brm/main/UserOverview/:id",
  Calibration = "calibration/*",
  ImpactCalibration = "/brm/main/ImpactCalibration",
  LikelihoodCalibration = "/brm/main/LikelihoodCalibration",
  Importer = "importer/*",
  ProjectImport = "/brm/main/ProjectImport/:id",
  AddUser = "/brm/main/AddUser",
  Reports = "/brm/main/Reports/*",
  LicenseError = "/LicenseError",
  Logout = "/logout",
  UserProfile = "/brm/account/profile",
}
