import { useQuery } from "@tanstack/react-query";

import * as BrmGql from "generated/graphql";
import * as Brm from "services/brm";
import { QueryOption } from "libs/react-query";
import * as KeyFactory from "./element-query-key-factory";
import { DetailTableType } from "../../../types/DetailTableType";
import { IElementMap } from "./types/elementInterface";
import { responseAsArray } from "./utils/responseAsArray";

interface IgetElementMitreD3fend {
  elementId: string;
  elementName: string;
  variantId: string;
  targetData: BrmGql.GetTargetAllocatedControlsQuery | undefined;
  systemAssetData: BrmGql.GetSystemAssetAllocatedControlsQuery | undefined;
  systemAssetTypeData: BrmGql.GetSystemAssetTypeAllocatedControlsQuery | undefined;
  sctmData: BrmGql.GetSctmAllocatedControlsQuery | undefined;
}

/**
 * returns list of all allocated controls
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of selected element
 * @param {string} variantId - uuid of the variant
 * @returns list of allocated controls for any given selected element
 */

export const getElementMitreD3fend = ({
  elementId,
  elementName,
  variantId,
  targetData,
  systemAssetData,
  systemAssetTypeData,
  sctmData,
}: IgetElementMitreD3fend) => {
  const opts = { variant: variantId };
  const mitreDefendMap: IElementMap = {
    [DetailTableType.RISKS.key]: (id) => Brm.riskApi.getRiskAllDefensivetechnique(id),
    [DetailTableType.VULNERABILITIES.key]: (id) => Brm.vulnerabilityApi.getVulnerabilityMitigation(id, opts),
    [DetailTableType.SCTM.key]: () => sctmData?.allocatedControls,
    [DetailTableType.ATTACKS.key]: (id) => Brm.attackApi.getAttackAllControl(id, opts),
    [DetailTableType.ATTACKERS.key]: (id) => Brm.attackerApi.getAttackerAllControl(id, opts),
    [DetailTableType.CONTROL_TYPES.key]: (id) => Brm.controlTypeApi.getControlTypeAllControl(id, opts),
    [DetailTableType.VARIANTS.key]: () => sctmData?.allocatedControls,
    [DetailTableType.NODES.key]: (id) => Brm.nodeApi.getNodeAllControl(id, opts),
    [DetailTableType.EXCHANGES.key]: (id) => Brm.exchangeApi.getExchangeAllControl(id, opts),
    [DetailTableType.THREAT_EVENTS.key]: (id) => Brm.threatEventApi.getThreatEventAllControl(id, opts),
    [DetailTableType.UNDESIRED_EVENTS.key]: (id) => Brm.undesiredEventApi.getUndesiredEventAllMitigation(id, opts),
    [DetailTableType.ASSETS.key]: (id) => Brm.assetApi.getAssetAllMitigation(id, opts),
    [DetailTableType.MISSIONS.key]: () => targetData?.target?.all_controls,
    [DetailTableType.RESOURCES.key]: () => targetData?.target?.all_controls,
    [DetailTableType.CAPABILITIES.key]: () => targetData?.target?.all_controls,
    [DetailTableType.PERSON.key]: () => targetData?.target?.all_controls,
    [DetailTableType.DATAFLOWS.key]: () => targetData?.target?.all_controls,
    [DetailTableType.ACTIVITIES.key]: () => targetData?.target?.all_controls,
    [DetailTableType.DATATYPES.key]: () => targetData?.target?.all_controls,
    [DetailTableType.SYSTEM_ASSETS.key]: () => systemAssetData?.systemAsset?.all_controls,
    [DetailTableType.SYSTEM_ASSET_TYPES.key]: () => systemAssetTypeData?.systemAssetType?.all_controls,
  };

  return responseAsArray({ map: mitreDefendMap, elementId, elementType: elementName });
};

export interface IElementMitreD3fendProps {
  elementId: string;
  elementName: string;
  projectId?: string;
  sctmId: string;
  variantId: string;
  options?: QueryOption<typeof getElementMitreD3fend>;
}

/**
 * custom-hook to retreive all controls of any selected element
 *
 * @param {string} elementId - uuid of any selected element
 * @param {string} elementName - type/category of the selected element
 * @param {string} projectId - uuid of project
 * @param {string} sctmId - uuid of SCTM
 * @param {string} variantId - uuid of variant
 * @param {object} config - configuration of react-query
 * @returns react-query for getting allocated controls
 */
export const useElementMitreD3fend = ({
  elementId,
  elementName,
  projectId,
  variantId,
  sctmId,
  options,
}: IElementMitreD3fendProps) => {
  const targetAllocatedControlsQuery = BrmGql.useGetTargetAllocatedControlsQuery(
    { target: elementId, variant: variantId },
    {
      enabled:
        options &&
        options.enabled &&
        !!elementId &&
        (elementName === DetailTableType.DATATYPES.key ||
          elementName === DetailTableType.ACTIVITIES.key ||
          elementName === DetailTableType.DATAFLOWS.key ||
          elementName === DetailTableType.PERSON.key ||
          elementName === DetailTableType.CAPABILITIES.key ||
          elementName === DetailTableType.RESOURCES.key ||
          elementName === DetailTableType.MISSIONS.key),
    }
  );

  const systemAssetAllocatedControlsQuery = BrmGql.useGetSystemAssetAllocatedControlsQuery(
    { id: elementId, project: projectId!, variant: variantId },
    {
      enabled:
        options && options.enabled && !!elementId && !!projectId && elementName === DetailTableType.SYSTEM_ASSETS.key,
    }
  );

  const systemAssetTypeAllocatedControlsQuery = BrmGql.useGetSystemAssetTypeAllocatedControlsQuery(
    { id: elementId, project: projectId!, variant: variantId },
    {
      enabled:
        options &&
        options.enabled &&
        !!elementId &&
        !!projectId &&
        elementName === DetailTableType.SYSTEM_ASSET_TYPES.key,
    }
  );

  const sctmAllocatedControlsQuery = BrmGql.useGetSctmAllocatedControlsQuery(
    { sctm: sctmId },
    {
      enabled:
        options &&
        options.enabled &&
        !!sctmId &&
        (elementName === DetailTableType.VARIANTS.key || elementName === DetailTableType.SCTM.key),
    }
  );

  const elementAllocatedControlsQuery = useQuery({
    ...options,
    queryKey: KeyFactory.defensiveTechniqueKeys.element(
      elementId,
      elementName,
      variantId,
      targetAllocatedControlsQuery.data,
      systemAssetAllocatedControlsQuery.data,
      systemAssetTypeAllocatedControlsQuery.data,
      sctmAllocatedControlsQuery.data
    ),

    queryFn: () =>
      getElementMitreD3fend({
        elementId,
        elementName,
        variantId,
        targetData: targetAllocatedControlsQuery.data,
        systemAssetData: systemAssetAllocatedControlsQuery.data,
        systemAssetTypeData: systemAssetTypeAllocatedControlsQuery.data,
        sctmData: sctmAllocatedControlsQuery.data,
      }),
  });

  if (targetAllocatedControlsQuery.isError) {
    return targetAllocatedControlsQuery;
  }

  if (systemAssetAllocatedControlsQuery.isError) {
    return systemAssetAllocatedControlsQuery;
  }

  if (systemAssetTypeAllocatedControlsQuery.isError) {
    return systemAssetTypeAllocatedControlsQuery;
  }

  if (sctmAllocatedControlsQuery.isError) {
    return sctmAllocatedControlsQuery;
  }

  return elementAllocatedControlsQuery;
};
